@import url("https://fonts.googleapis.com/css2?family=Sen:wght@400;700;800&display=swap");

@font-face {
  font-family: "Sen", sans-serif;
  font-style: normal;
  font-weight: normal;
  src: local('Circular Std Book'), url('circular-std/CircularStd-Book.woff') format('woff');
}

.App {
  text-align: center;
  font-family: "Sen", sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body {
  font-family: "Sen", sans-serif !important;
}
.containerzz {
  background: #0070ff;
  color: white;
  border-radius: 20px;
  padding: 20px 7px 20px 7px;
}
.containerzz0 {
  background: #ef3800;
  color: #000f20;
  border-radius: 20px;
  padding: 20px 7px 20px 7px;
}
.border-nav{border-bottom: 1px solid #e6e6e6;}
.mt-6 {
  margin-top: 7rem !important;
}
.w35{width:35px;}
.w18{width:18px;}
.txtlogo{padding: 10px;background:#000f20;color:#fff;border-radius: 5px;text-transform: capitalize;font-weight: bold;}
.containerzz h3 {
  margin: 0;
  font-size: 20px;
}

.containerzz p {
  margin: 5px 0 15px;
  font-size: 14px;
  opacity: 0.9;
}

.containerzz .grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.containerzz .card {
  background: rgba(255, 255, 255, 0.2);
  margin-bottom: 0px;
  padding: 10px;
  border-radius: 15px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: bold;
  transition: all 0.3s ease;
}

.containerzz .card:hover {
  background: rgba(255, 255, 255, 0.3);
  transform: scale(1.05);
}

.containerzz .card span {
  opacity: 0.8;
}
.prc {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 5px 1px rgb(220, 220, 220);
}
.delloc{padding-left:4%;border-radius: 10px;}
.upgrade{border-radius: 10px;background-color: #c4ffe3;font-size: 0.8em !important;margin-left: 2%;padding: 2px 5px 2px 5px;border: 1px solid #aeaeae;}  
.br-0{border-radius: 0 !important;}
.mh400{margin:0 auto;height: 400px;}
.yrprc {
  text-align: center;
  padding: 0;
  font-size: 0.5em;
  color: #fff;
  border-radius: 10px;
  padding: 2px 5px;
}
.prc h4 {
  text-align: center;
}
.pmhd {
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
}
.p-prc {
  font-size: 3.5em;
  text-align: center;
  padding-top: 10px;
  color: #333;
  font-weight: bold;
}
.prc span {
  font-size: 0.7em;
}
.mtopcon0 {
  margin-top: 40px;
  min-height: 950px;
}
.pt-6indx{padding:5rem 2rem 2rem 2rem !important;}
.ovrflwhdn{overflow: hidden;}
.wappcomms {
  width: 45px;
  height: 45px;
  background: #198754;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #fff;
  border-radius: 50%;
  position: fixed !important;
  top: 90%;
  left: 30px;
  z-index: 9;
  text-align: center;
  line-height: 45px;
  cursor: pointer;
  transition: all 0.3s ease-out 0s;
}
.wappcomms0 {
  width: 75px;
  height: 45px;
  background: #198754;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #fff;
  border-radius: 10px;
  position: fixed !important;
  top: 85%;
  right: 15%;
  z-index: 9;
  text-align: center;
  line-height: 45px;
  cursor: pointer;
  transition: all 0.3s ease-out 0s;
}
img.rounded-circle, .rounded-circle {
  box-shadow: 0 0 5px rgba(0,0,0,0.2);
  width: 40px; height: 40px; object-fit: cover;
  border: 2px solid #3d3d3d;
}
.mln10{margin-left:-10px;}
.shopdtlcontmax{max-height:70vh !important;overflow:scroll;}
.tp80{top: 80% !important;}
.pricingtext{height: 30px;line-height: 30px;}
.pricingtext svg{width:20px;height:20px;margin-top:-5px}
.ctabtn{width:99% !important;color: #333 !important;border:2px solid #0070ff !important;font-weight: bold !important;}
.bg-blue{background: #0070ff !important;}
.savegreen{background:#00cb93;}
.plpt{padding-left: 0.5em !important;padding-top: 3%;}
.pt-035{padding-top: 0.35em !important;}
.main-btn.border-btn {
  border: 2px solid #0070ff;
  background: transparent;
  color: #0070ff;
}
.btn-hover {
  position: relative;
  overflow: hidden;
}
.main-btn {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  padding: 17px 35px;
  font-size: 18px;
  line-height: 1;
  border-radius: 50px;
  cursor: pointer;
  z-index: 5;
  transition: all .4s ease-in-out;
}
.dsktp-pd{padding-top: 11px;}
.navstaticon{width: 35px;height: 35px;}
.list-group-item{border-bottom: 1px solid #f4f4f4 !important;color:#777 !important;}
.bgbluetop-nav{background-color: #0070ff;border-radius: 20px;}
.pl-0{padding-left: 0 !important;}
.pr-0{padding-right: 0 !important;}
.pr-01{padding-right: 0 !important;}
.pl-2{padding-left: 0.25rem !important;}
.pr-2{padding-right: 0.25rem !important;}
.bgbluishbg{background-color: #0070ff !important;}
.font90{font-size: 0.9em !important;}
.font90x{font-size: 1.18em !important;}
.pay-logo{width: 100%;}
.btn-light{background-color: #f0f0f0 !important;}
.addpymt svg{margin-top:-2px;height: 20px;}
.addpymt svg{font-size: 0.9em;}
.ht40{height: 40px !important;}
.mt-40{margin-top:48px !important;}
.acc-con{height: 50px;overflow:hidden;}
.acc-con svg{width: 20px;border-radius: 5px;}
.acc-con svg:hover{background-color: #f2f6f8;padding: 1px;}
.pay-li{background-color: #fff !important;padding: 0 !important;border-radius: 10px;height: 50px !important;}
.pay-li button{padding: 0 !important;border:2px solid #555;width: 100%;height: 100%;}
button.active{border-bottom: 2px solid #0070ff !important;color: #0070ff !important;font-weight:bold;background-color: #fff !important;border-radius:5px;}
.pay-li button.active{border: 2px solid #0070ff !important;color: #333 !important;font-weight:bold;background-color: #fff !important;border-radius:5px;}
.catdivside{height: 150px;
  border-radius: 10px;
  margin-left: 7% !important;
  width: 80% !important;overflow:hidden;}
.pymt label{font-size: 0.9em;padding-bottom: 5px;}
.grey-text{color: #777 !important;}
.grey-text0{color: #555 !important;}
.ph-header-con{height: 40px;line-height: 40px;}
.mt70{margin-top: 70px !important;}
.modal-backdrop{z-index: 1 !important;}
.pymtcon{box-shadow: 0px 2px 6px rgba(0, 0, 0, .1), 0px 0px 0px 1px rgba(0, 0, 0, .02);border-radius: 7px;padding:1% 4% 4% 4% !important;}
.dropdown-item0:hover li{background-color: #eee;border-radius: 7px;}
.dropdown-item0 li{padding: 5px;}
.f14{font-size: 1.4em;}
.font-9{font-size: 1em;}
.h-80{height:80% !important;}
.orderscontainer{min-height:900px !important;overflow: scroll;}
.pb-0{padding-bottom:0px !important}
.mh100{max-height:100px !important;overflow: scroll;}
.font9{font-size: 0.9em !important;}
.f85{color:#959595;font-size: 0.8em !important;}
.f9{color:#2d2d2d !important;font-size: 0.8em !important;}
.detail-con .row{margin-left: 0 !important;margin-right: 0 !important;padding-left: 0 !important;padding-right: 0 !important;}
footer{height: 76px !important;}
.prodt svg{width: 20px !important;}
.pos svg{height: 20px !important;margin-top: -4px;}
.mrftr0{font-size:1.5em;font-weight:bold;}
.mrftr1{font-size:1.1em;line-height:31px;}
.mrftr2{width:100%;height:250px;border-radius:10px;overflow:hidden;}
.mrftr3{border-radius:18px;background:#fff;padding:25px;margin-bottom:20px;}
.mrftr3{border-radius:18px;background:#fff;padding:20px;margin: 10px 0 20px 1%;width:98%;}
.f14{font-size:1.4em !important;}
.orange{background-color: #ef3800 !important;border:none !important}
.font35{font-size: 3.5em !important;}
.orange-font{color: #ef3800 !important;}
.sizeerrcon{position: fixed;top:10%}
.text-blue{color: #25a0ff !important;}
.mw400{max-width: 400px !important;}
.h400scroll{height: 400px;overflow: scroll;}
.btn-sk {
  background: #25a0ff !important;width: 100%;
  color: #fff !important;
  border: none;
  border-radius: 4px;
}
.btn-info{background-color: #25a0ff !important;border:none !important}
.bluetxt{color: #25a0ff !important;}
a{text-decoration: none !important;}
.txtlight{color:#888;}
.sidebar-header{border-bottom:1px solid #e1e1e1 !important;}
.sidebarcon{border-top: none !important;border-radius: 0px !important;border:none;padding-bottom: 100px;}
.con-responsive{border-radius: 10px;overflow:hidden;}
.con-responsive .row{line-height: 40px;color:#333;padding:10px 10px 10px 10px;border-top:1px solid #e0f2ff;}
.con-responsive img{height:40px;width:40px;border-radius: 7px;}
.con-responsive input{height:50px;border:none;}
.nav-icon-info{position: absolute;bottom: -15px;font-size: 0.8em;left: 0;}
.cat_div{width: 300px;
  left: 0;
  margin: auto !important;
  right: 0;height: 100vh;overflow: scroll;
}
.text-left{text-align: left !important;}
.cart-con{box-shadow: 0px 2px 3px rgba(0,0,0,0.25);background-color: #f8f8f8;height: 160px;border-radius: 7px;overflow: hidden;margin-bottom: 10px;margin-right:5% !important;margin-left: 0 !important;}
.cart-con svg{margin-top: 40%;color: #939393;}
.cart-con select{border: none;background: inherit;}
.h60{height: 70%;}
.pay-cart{height: 45px;line-height: 45px;border-bottom: 1px solid #eee;}
.pay-cart select{width: 100%;border:none;background: inherit;}
.pay-cart input{height: 40px;border: none;width: 100%;}
.seller-img{width: 100px;height: 100px;border-radius: 50%;}
.seller-name a{color:#333;font-size: 1em;text-decoration: none;}
.share-btn{line-height: 60px;color: #25A0FF;cursor: pointer;}
.cat-input{width: 90% !important;}
.br-10{border-radius: 5px !important;}
.likebtn0{padding:10px 10px 4px 10px !important;}
.likebtn{padding:0 0 10px 10px !important;}
.likebtn button{width: 30px;height: 30px;padding: 0;}
.likebtn svg{width: 20px;}
.inwish{color: #ef3800;}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
li{list-style: none;}
.mtopcon{
  margin-top: 100px;
  min-height: 950px;
}
.reglogincon{
  width: 300px;
  left: 0;
  margin: auto;
  right: 0;
}
.bg-shade{background-color: #f2f5f8 !important;}
.articles {
  font-size: 0.9em !important;
  color: #333 !important;
}
.tstmnl{background-color: #eff8ff;border-radius: 7px;padding: 10px 0 2px 0;}
.banner{width: 100%;max-width: 1320px;height: 300px;background-color: #939393;border-radius: 10px;}
.number1 {
  font-weight: 500 !important;color: #333;
}
.prflbtn{height: 45px;}
.prfl-img{width: 80px !important;height: 80px !important;border-radius: 50%;margin-right: 24px;line-height: 80px;text-align: center;font-size: 3.2em;color: #fff;background: #25a0ff;margin-left: 10%;}
.strprfldtls{padding-top: 10px;}
.dropdown-header{font-size: 1.25em !important;}
.dropdown-header svg{height: 35px !important;width: 35px !important;}
.nav-item:hover{background-color:#e3f5ff !important;border-radius: 7px;}
.pointer{cursor: pointer;}
.topnavusricon{
padding: 5px 5px 5px 12px;
border-radius: 5px;}
.topnavusricon svg{width: 30px;height: 30px;border-radius: 50%;background-color: #0070ff;margin-right: 7px;color:#fff;}
.nav-con{border-bottom: 1px solid #e6e6f2;box-shadow: 0px 0px 28px 0px rgba(82, 63, 105, 0.13);}
.dashnav{max-width: 100% !important;}
.hidenav0{display: none !important;}
.ml5{margin-left: 5% !important;}
.addprobtmnav{background-color: #25A0FF;width: 100%;line-height: 45px;height: 45px;border-radius: 18px;color:#fff;margin-left: 3%;}
.addprobtmnav svg{height: 35px;width: 35px;}
.cart-pro-ttl2{color: #939393;font-size: 0.8em;}
.cart-pro-ttl{color: #25A0FF;font-weight: bold;}
.cartordcon .ord-img{width: 70px !important;height: 70px !important;}
.bgbtna{color:#fff !important;}
.bgbtna:hover{background-color: #fff !important;border-radius:10px;color:#222 !important;}
.sideproficon a{border: 3px solid #222;border-radius: 20px;padding: 2px !important;margin: 10px 0 10px;text-align: center;}
.navftrs .col-12:hover{background-color: #ecf5fc !important;}
.icon-wrapper{
  width: '40px';
  height: '40px';
  border-radius: '50%';
  background-color: '#f8f9fa';}
  .h50{height:50px !important;}
.marquee-content {
  display: flex;
  animation: scrolling 40s linear infinite;
}.marquee-content0 {
  display: flex;
}
.marquee-item {
  flex: 0 0 11vw;
  margin: 0 1vw;
}
.marquee-item img {
  display: block;
  width: 100%;
  padding: 0 20px;
}
@keyframes scrolling {
  0% { transform: translateX(0); }
  100% { transform: translatex(-144vw); }
}
.display-none{display:none !important;}
.feature-imgcon{width:100%;height:250px;border-radius:10px;overflow:hidden;}
.feature-imgcon img{width:100%;height:100%;}
.paymethodscon{width:100%;height:40px;background:#333}
.paymethodscon img{width:100%;height:100%;}
.logo{width: 125px;}
.navdropdowndiv{width: 350px !important;}
.welcome-banner{max-width:900px;width:100%;height:auto;margin:0 auto;}
.navdropdowndiv .dropdown-item{padding: 10px 10px 10px 10px !important;}
.navdropdowndiv svg{width:32.5px;height:32.5px;}
.me-4 {
  margin-right: 2rem !important;
}
.section-ttl{font-size: 1.6em;font-weight: 500;}
.fsz-95{font-size: 1em;}
.fsz-1{font-size: 1.2em;}
.prod-col{width: 20% !important;}
.text-price{color:#25A0FF !important;}
.delete-imgbtn{position: absolute;padding:4px;border-radius:10px;background-color: red;color: #fff;}
.fttxt a{text-decoration: none !important;color:#333;}
.offcanvas{width: 70% !important;}
.hidenav{display:none !important;}
.details-indicators{width: 12px !important;height: 12px !important;border-radius: 50%;background-color: #ff3000 !important;}
.pro-img{height: 500px;}
.pro-box{overflow: hidden;}
.vwallbtn {border:1px solid #e3e3e3 !important;background-color: inherit !important;border-radius: 10px !important;font-size: 1em !important;text-decoration: none;color:black;height:40px;line-height: 30px !important;}
.str-con{background: #25A0FF;color: #fff;border-radius: 50%;width: 32.5px;height: 32.5px;line-height: 32.5px;text-align: center;text-transform: uppercase;float: left;margin:0;}
.str-con2{color:#000;padding-left: 10px;height: 32.5px;text-align: center;float:left;margin:0;line-height: 32.5px;max-width: 80px;overflow: hidden;font-size: 0.8em;text-align: left;}
.prodcon{box-shadow: 0px 2px 6px rgba(0, 0, 0, .1), 0px 0px 0px 1px rgba(0, 0, 0, .02);border-radius: 7px !important;border:none !important;border-radius: 7px !important;overflow: hidden;float: left;width:100%;}
.prodcon img{width: 100%;height: 12vw;}
.prodcon .catimg{width: 100%;height: 140px;}
.pro-title{overflow: hidden;height:21px !important;}
.pro-title a{color: #000;text-decoration: none;}
.w500{max-width: 500px !important;}
.mbl-srch{display: none !important;font-size: 0.8em !important;}
.btm-nav{display: none !important;font-size: 0.7em !important;}
.btm-nav a{color:#717171;}
.section-title-con{height:40px;line-height: 40px;}
.dropdown-menu{width:250px;padding: 10px 10px 20px 10px !important;}
.dropdown-item0{width:80% !important;overflow: hidden;font-size: 1.2em;}
.actv-drop{color:#25a0ff !important;}
.dropdown-header{font-weight: bold;color: #b9b9b9;height: 40px;line-height: 40px;}
#target{display: none;}
.sidebar-cat{width: 400px;min-height: 1000px;height: 100%;background:#eff8ff;position: absolute;right: 0;top:0;z-index: 1100;}
.cover{min-width: 500px;width: 100%;min-height: 1000px;height: 100%;position: fixed;top: 0;left:0;background-color: #000;opacity: .7;overflow: hidden;z-index: 1080;}
.sharelinks{background-color: #f4f4f4;padding: 10px;border-radius: 10px;width: 180px;border:1px solid #e8e8e8;position: absolute;margin-top: -10px !important;z-index: 10;}
.sharelinks svg{width: 32px;height: 32px;}
.fb{color: #0070ff;}.wa{color: #26ff00;}.tw{color: #00bbff;}
.body-font{font-size: 1em;}
.carousel button.active{background: none !important;}
.new-card{box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);background-color: #fff;padding: 10px 0 10px 0;border-radius: 10px;}
.new-card2{background-color: #fff;box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);padding: 10px 0 10px 0;border-radius: 10px;}
.shp-logo-dtl svg{width: 40px;height: 40px;}
.filter{background-color: inherit;height: 40px;border-radius: 10px;border: 2px solid #e1e1e1;font-size: 0.8em;}
.filter2{background-color: #fff;border-radius: 10px;font-size: 1em;padding: 10px;border: 1px solid #e6e9ec;height: 40px;}
.shoptab{color:#333;}
.dash-top-row{height: 40px;line-height: 40px;margin-bottom: 40px;}
.btnhght{height: 40px !important;}
.sidebar-dark {
  background-color: #eff8ff;
  -webkit-box-shadow: 0px 0px 28px 0px rgba(82, 63, 105, 0.13);
  box-shadow: 0px 0px 28px 0px rgba(82, 63, 105, 0.13);
}
#details-top .details-right ul li {
  list-style: none;
  color: gray;
  padding-bottom: 2px;
}
.size-pick-con li {
  font-size: 1.4em;
  border-radius: 10px;
  height: 50px;
  border: 1px solid gray;
  float: left;
  margin: 2% 0 0 2%;
  line-height: 50px;
}
.size-pick-con li .left {
  width: auto;
  height: 100%;
  float: left;
  text-align: center;
  font-weight: bold;
  color: black;
  margin:0 10px 0 10px
}
.size-pick-con li .right {
  width: 40px;
  height: 100%;
  float: left;
}
.size-pick-con li .right input[type="radio"] {
  border: 4px solid #24a0ff;
  padding: 0.5em;
  appearance: none;
}
.size-pick-con li .right input {
  border-radius: 10px;
  width: 90%;
  height: 80%;
  margin-top: 12%;
}
.size-pick-con li .right input[type="radio"]:checked {
  background: #ef3800;
  border: 2px solid #ef3800;
}
input::placeholder {
  color: rgb(194, 194, 194) !important;font-size: 0.85em !important;
  opacity: 1;
}
.nav-left-sidebar {
  position: fixed;
  width: 270px;
  height: 100%;
  top: 60px;
  overflow: auto;left: 0;font-size: 1em;
}
.nav-left-sidebar .navbar-nav .nav-link {
  padding: 12px;
  color: #222 !important;
  transition: 0.3s;
}
.nav-left-sidebar .navbar {
  position: relative;
  padding: 14px;
}
.nav-ord-span {
  float: right;
  margin-right: 10px;
  background: #25a0ff;
  color: white;
  padding: 0 5px 0 5px;
  border-radius: 50%;
}
.sidebar-dark.nav-left-sidebar .nav-link svg {
  color: #222;
  margin-top: -5px;
}
.nav-link svg {
  width: 20px;
  margin-right: 9px;
  text-align: center;
  vertical-align: middle;
  line-height: 16px;
}
.nav-divider {
  padding: 10px 14px;
  line-height: 30px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.85em;
  color:#25A0FF;
}
.nav-left-sidebar .navbar {
  position: relative;
  padding: 14px;
}
.search-container {
  position: relative;
  width: 100%;
}
.accordion-button:not(.collapsed){
  .navftrs .col-12:hover{background-color: #ecf5fc !important;}box-shadow: none !important;}
.suggestions-list {
  position: absolute;
  top: 100%;
  max-width: 300px;
  width: 100%;
  background: #f2f2f2;
  border: 1px solid #777;
  z-index: 10;
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 250px;
  overflow-y: auto;
  border-radius: 10px;
}

.suggestions-list li {
  padding: 8px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.suggestions-list li:hover {
  background-color: #f8f8f8;
}

.suggestion-image {
  width: 40px;
  height: 40px;
  object-fit: cover;
  margin-right: 10px;
}

.suggestion-details {
  width:80%;
  display: inline-block;
  vertical-align: middle;
}

.suggestion-title {
  display: block;
  font-size: 14px;
  font-weight: 600;
}

.suggestion-price {
  font-size: 12px;
  color: #555;
}

.background0 {
  background: linear-gradient(135deg, #0a0f29 0%, #1c2b5a 100%);
  background-size: cover;
  color: white;
  padding: 50px;
  text-align: center;
}

.content0 {
  padding: 0 5% 0 5%;
  position: relative;
  z-index: 1;
}


.card {
  border-radius: 10px !important;
  overflow: hidden;
  margin-bottom: 30px;
  border: none !important;
  -webkit-box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);
  -moz-box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);
  box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);
}
.dash-min{width: 264px !important;}
.dash-max {
  position: relative;
  left: 0;
  margin-left: 280px;
  min-height: 999px !important;padding: 4px 30px 60px 30px !important;width: 84% !important;
}
.currency{width: 100%;
  height: 40px;
  background: #fff;
  border-radius: 7px;
  border: 2px solid #232331;
  padding: 0 10px 0 10px;}
.dashnavshw{margin-left:270px !important;background-color: #f6fcff !important;max-width: 84.5% !important;position: absolute;padding: 20px 0 20px 0;}
.card-body{padding: 1.25rem !important;}
.metric-value {
  margin-bottom: 0;
  line-height: 1;
  white-space: nowrap;
}
.dtlspg{width: 1500px !important;}
.float-right {
  float: right !important;
}
.fgreen {
  color: #2AA501 !important;
}
.ksh {
  font-size: 0.5em;
  color: inherit;
}
.clear{clear: both;}
.upimgcon{width: 100px !important;}
.addimg{width: 80px;height: 80px;border: 2px dashed green !important;margin-top: -100px;}
.addimg svg{color:green;position: absolute;margin-left: -12px;margin-top: -10px;}
.addimg input{opacity: 0;}
.img-div {
  position: relative;
  width: 70px;height: 85px;;
  float:left;
  margin-right:5px;
  margin-left:5px;
  margin-bottom:10px;
  border-radius: 7px;overflow: hidden;
}
.opzero{opacity: 0;z-index: 1 !important;position: relative;height: 80px !important;width: 80px !important;}
.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: 100%;
  max-width: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}
.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  font-size: 0.7em;
}
.img-div:hover .image {
  opacity: 0.3;
}
.img-div .middle {
  opacity: 1;
}
.addprobginpt{background-color: #eee  !important;border:none !important}

.dtlsbtmnav{z-index:1100;display: block;width: 99.5% !important;font-size:1em !important;}
.dtlsbtmnav button svg{margin-right: 5px;}
.dtlsbtmnav button{height: 45px;}
.dashb{max-width: 100% !important;background-color: #f6fcff !important;margin-top: 55px;padding-top: 30px;}
.shpnm{background:#25a0ff;color: #fff !important;}
.shpnm-msg{border-bottom: 5px solid #25a0ff;}
.display-4{font-weight: bold;}
.pageheader-title {
  font-size: 1em;font-weight: bold;
}
.font-bold{font-weight: bold !important;}
.carousel-control-prev span{background-color: #25a0ff !important;border-radius: 50%;}
.carousel-control-next span{background-color: #25a0ff !important;border-radius: 50%;}
.dtlspage .strloc{font-size: 0.9em;}
.card-header {
  background-color: #fff !important;
  border-bottom: 2px solid #ddd !important;
  font-size: 1em;
  padding: 16px !important;
}
.grey-textd5{color: #d5d5d5 !important;}
.poppro{position:absolute;right: 18px;margin-top: 18px;}
.poppop-img-con{height:50px;width:70px;padding-right: 18px;}
.poppop-img-con img{width:100%;height: 100%;border-radius: 5px;}
.popcon{line-height: 60px;padding: 11px 18px !important;}
.poppop-img-con-name{overflow:hidden;height:50px;width:80%;padding-right: 18px;}
hr{color:#adb5bd !important;}
.badge {
  display: inline-block;
  padding: 3px 7px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;background-color: #2AA501;font-size: 0.9em;
}
.ltst_stk {
  margin: 0;
  padding: 0;
  width: 100%;
}
.prgrss {
  height: 2.5rem !important;
}
.ltst_stk .ltpro {
  width: 31%;
  height: 140px;
  float: left;
  margin: 0 0 10px 2%;
  border-radius: 10px;
}
.card-title{font-size: 0.9em;}  
.card-title svg{width: 18px;height: 18px;}
.ord-bg{background-color: #f2f6f8;border-radius: 18px;line-height: 50px;margin:0 0 10px 0 !important;}
.invoice-ord-bg{line-height: 25px;}
.ord-img{width: 50px;height: 50px;border-radius: 16px;overflow: hidden;}
.br-10{border-radius: 10px !important;}
.br-18{border-radius: 18px !important;}
.br-50{border-radius: 50% !important;width: 50px;height: 50px;padding: 0;}
.br-50 svg{text-align: center !important;}
.bx-shadow{-webkit-box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);-moz-box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);}
.ord-div{margin:0 0 14px 0 !important;padding: 18px !important;}  
.lh-50{line-height: 50px !important;}
.sidebarcon ul li{
  padding: 0 7% 0 7%;font-size: 1.05em;margin-bottom: 5px;
}
.bg-dark{color: rgb(40, 55, 70) !important;}
.rownumsn{margin-top: -5px !important;}
.vndr{
  background: #25a0ff;text-align: center;
  max-width: 200px;overflow: hidden;
  border-radius: 50%;
}
.p-topnavcon{margin-left: 10%;}
.wusrmodal{max-width: 380px !important;}
.wusrmodal ul li{border-radius: 18px;}
.text-right{text-align: right !important;}
.vndr svg{width: 30px;height: 30px;margin: 0;}
.vndr a:hover{color:  #333 !important;}
.order-bg .row{
  padding-right: calc(var(--bs-gutter-x) * 0.2) !important;
}
#confirmdelModal5{z-index: 100000;}
.ordststsdiv .row > *{
  padding-right: calc(var(--bs-gutter-x) * 0.2) !important;padding-left: calc(var(--bs-gutter-x) * 0.2) !important;
}
.h40{height: 40px !important;}
.copybtn:hover{background:#fff !important;color: #000 !important;border:none !important}
.ord_outrbx_div button {
  border: none;
  background: #333;
  color: #fff;
  width: 100%;
  margin: 0 auto;
  border-radius: 7px;
  height: 40px;
}
.cntr {
  max-width: 350px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.h25{height: 25px !important;}
.shplginv{background-color: #222;color: #fff;width: auto;}
.pl-nw{padding-left: 2% !important;}
.pl-nw0{padding-left: 5% !important;}
.topheadersvg{width: 18px;height: 18px;}
.ord-btns button{height: 40px !important;}
.orddetailspopcon{background:#eff8ff;position: relative;right: 0;top:0;z-index: 1100;max-width: 450px;max-height: 100vh;overflow: scroll;font-size: 1.1em !important;}
.orddetailspopcon .ord-bg{line-height: 20px !important;height: 76px;}
.ordbgrw{padding-right: 0 !important;padding-left: 0 !important;}
.orddetailspopcon .ord-sz{font-size: 0.9em;height: 22px;overflow: hidden;padding: 1% 0 0 3% !important;}
.ord-sznav{height: 40px;}
.p-topnav{height: 20px;;}
.ord-sz1{padding: 1% 0 0 3% !important;font-size: 0.9em !important;}
.abslt-con{position: absolute;left: 50%;}
.insd{position: relative;left: -50%;}
.pageheader-title{font-size: 1.2em;}
.usrpopbtn{height: 40px;font-size: 1em !important;width: 45% !important;margin-left: 4% !important;}
.lh34{line-height: 44px;}
.hvr:hover{background-color: #e1e1e1;}
.vwallbtn svg{width: 20px;margin: -6px 0 0 4px;}
.text-dark a{color:#000f20 !important;}
.text-dark{color:#000f20 !important;}
.float-left{float: left;}
.rnwbtn{background: #25a0ff;color: white;font-size:1.1em;padding: 10px 10px;border-radius: 10px;width: 220px;}
.ml-2{margin-left: 10px;}.mr-2{margin-right: 10px;}
.price1yr-bg{background:#ef3800;}
.price2yr-bg{background:#00cb93;}
.font-24 {
  font-size: 20px;
}
.user-avatar-lg {
  height: 48px;
  width: 48px;margin-right: 20px;
}
.yrprc {
  text-align: center;
  font-size: 0.8em;
  color: #fff;
  padding: 2px 5px;
}
.pabs{
  max-width: 400px;width: 100%;
  position: fixed;
  top: 70px;
  left: 45%;
  margin: auto;
  right: 0;
  z-index: 16000;
}
.fbold{font-weight: bold !important;}
.reglogincon input{height: 40px !important;}
.orderdtls-info{padding-left: 20% !important;}
.bg5{background-color: #555 !important;color:#fff !important;}
.hddsktp{display: none !important;}.hddsktp0{display: none !important;}
.wthdrwtbl{border-radius: 10px;
  overflow: hidden;
  border: 1px solid #d5d2d2;}
.hide{display: none !important;}
#accordionFlushExample{background:#eff8ff;}
.pymtdiv #accordionFlushExample{background:#fff !important;}
#accordionFlushExample li{padding:0;background:#eff8ff;}
#accordionFlushExample .accordion-body{padding: 2px 10px 0 10px !important;background:#fff;font-size: 0.9em;}
#accordionFlushExample .accordion-body li{background:#fff;}
.hdmobile{height: 40px;line-height: 40px;padding: 0 0 0 10px !important;}
.strloc svg{width: 16px;position: relative;top:-2px;}
.tstmnl p{font-size: 0.7em !important;}
.topdate svg{margin-top: -7px;padding-right: 5px;width: 30px;}
.graph{width: 100%;height: 250px;background: #adb5bd;}
.card-header{height: 44px;line-height: 44px;padding: 0 !important;}
.card-header button{font-size: 1em;line-height: 0px;}
.card-header button svg{width: 20px;height: 20px;margin-right: 2px;}
.ord-sz-dsk{height: 25px;line-height: 25px;}
.bg-default{background-color: #f6fcff !important;}
.dsktp-ord{box-shadow: none !important;}
.zindx{z-index: 1 !important;}
.hidemobile{display: inline-block !important;}
.or-container {
  align-items: center;
  color: #ccc;
  display: flex;
  margin: 25px 0;
}

.line-separator {
  background-color: #ccc;
  flex-grow: 5;
  height: 1px;
}
.number1 svg{width: 18px;}
.or-label {
  flex-grow: 1;
  margin: 0 15px;
  text-align: center;
}
.paymethod input[type=checkbox], input[type=radio] {
  width: 25px;
  height: 25px;
  text-align: left;
}
.ml5{margin-left: 5%;}
.btp1{border-top:4px solid #ef3800 !important;}
.btp2{border-top:4px solid #25a0ff !important;}
.btp3{border-top:4px solid #00cb93 !important;}
.dsktp-ord .ord-sz{font-size: 0.95em !important;height: 22px !important;line-height:22px;overflow: hidden !important;padding: 1% 0 0 3% !important;}
.cartordcon .ord-sz{font-size: 0.95em !important;height: 38px !important;line-height:35px;overflow: hidden !important;padding: 1% 0 0 3% !important;}
.cartordcon svg{margin-top:18px !important}
.ordhdr{font-size: 1em;}
.cartordcon img{width: 100%;height: 100%;}
.accordion-button:not(.collapsed){border-radius: 7px !important;}
.btmnavactive svg{color: #25a0ff;}
.btmnavactive div{color: #25a0ff !important;}
.lh35{line-height: 35px;}
.lh0{line-height: 18px !important;}
.testmnl svg{width: 18px;height: 18px;color:#ef3800;}
.carousel-indicators [data-bs-target]{background-color:#8f8f8f !important;border:none !important;height: 10px !important;border-radius: 7px;}
.carousel button.active{background-color:#25a0ff !important;border:none !important;}
.f7{font-size: 1em !important;}
.mb40{margin-bottom: 40px;}
.lhrvwrating{line-height: 40px;}
.mlrnew{margin-left:calc(0 * var(--bs-gutter-x)) !important;}
.rwdlogo{margin-top:-4px;}
.bgdefault-a{background-color: #f6fcff !important;}
.invoiceicons{background: black; color: #fff; border-radius: 50%; padding: 5px; width: 35px; height: 35px;}
.trmslist li{list-style: disc !important;padding-left: 1rem !important;}
*::-webkit-scrollbar{width:10px}
*::-webkit-scrollbar-thumb{border-radius:10px;background-color:#25a0ff}
.a4-container {
  width: 210mm; /* Full width of the viewport */
  height: 297mm; /* Maintain aspect ratio */
  max-width: 210mm; /* A4 max width */
  max-height: 297mm; /* A4 max height */
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 0.85em;
  transform-origin: top left;
}
.a4-container5 {
  width: 210mm; /* A4 width */
  height: 297mm; /* A4 height */
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform-origin: top left;font-size: 0.85em;
}
.pdfcon{width: 100%;height: 100vh;overflow: scroll;background-color: inherit;}
.thmax{line-height: 15px;}
.thmin{line-height: 27px;}
.mtn4{margin-top: -4px;}
.mtn2{margin-top: -2px;}
.font2em{font-size: 1.25em;}
.bg-grey{background-color: #f0f0f0 !important;}
.invcpdf thead{border-top:2px solid #777;border-bottom:2px solid #777;}
.b2pxgrey{border-bottom:2px solid #777 !important;}
.b1pxgrey{border-bottom:1px solid #777 !important;}
.border-bottom-none{border-bottom: none;}
.invcaddcon-default{width: 100%;height: 40px;line-height: 40px;background:#777;}
.invcaccordion::after {display: none;}
.invcaccordion:not(.collapsed){border-radius: 0 !important;}
.bgaccr{background:#cfe2ff;}
.h100{height:100% !important;}
.w100{width:100% !important;}
.invc-ord-bg{line-height: 18px !important;}
.tc{text-transform: uppercase;}
.tcone{text-transform: capitalize;}
.dsktpbtn-pdf-con{width: 500px;height: auto;background-color: #25a0ff;position: fixed;top:80px;border-radius: 10px;color: #fff !important;}
.invcsts{position: absolute;background-color: #333;color:#fff;border-radius: 5px;margin-left: 20%;}
.invcsts0{background-color: #333;color:#fff;border-radius: 5px;}
.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: inherit !important;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
}
.font7{font-size: 0.7em !important;}
.font8{font-size: 0.9em !important;}
.cat-imgcon{background-color: rgb(184, 184, 184);height:150px;width: 100%;border-radius: 10px;overflow:hidden;}
.cat-imgcon img{width:100% !important;height:100% !important;}
.ecartimg{max-width:500px;width:100%;min-height:300px;height:100%;margin: 0 auto;}
.ecartimg img{width:100%;}
.row {
  margin-right: calc(0 * var(--bs-gutter-x)) !important;
}
.pr-3 {
  padding-right: 1rem !important;
}
.text-white0{color:#fff !important;}
.logo1{max-width:148px !important;height: 44px;overflow: hidden;}
.bg-blue0{background: rgb(0,112,255);background: linear-gradient(180deg, rgba(0,112,255,1) 38%, rgba(5,115,255,1) 50%, rgba(255,255,255,1) 100%);}
.navftrs{width: 1100px !important;}
.navftrspd{padding:7% 1% 0% 7% !important;}
@media only screen and (min-width: 100px) and (max-width:1023px) {
.hidenav{display:block !important;}
}
@media only screen and (min-width: 100px) and (max-width:600px) {
  .content0 {
    padding: 7% 5% 7% 5%;
  }
  .a4-container0 {
    height: auto;
    position: absolute;
    left: 0;
  }
  .a4-container5{position: absolute;left: 5%;}
  .navdropdowndiv svg{width: 24px !important;height: 24px !important;}
  .wappcomms0 {right:15px !important;}
  #image_preview1{height: 140px !important;overflow: hidden;background-color: #f4f4f4;border-radius: 10px;}
  .hmintrotxt0{font-size: 0.85em !important;}
  .sidebar-header h6{font-size: 0.9em !important;}
  .cat-imgcon{height:90px !important;}
  .catdivside{height: 100px;
    width: 80% !important;overflow:hidden;}
  .suggestions-list{max-width: 100vw !important;width:92%;}
  .ecartimg{min-width:300px !important;}
  .pr-01{padding-right: 20px !important;}
  .pymtdiv{padding: 0.7em !important;}
  .p-prc {
    font-size: 2.7em;
  }
  .text-white0{color:#222 !important;}
  .bgbtna{color:#222 !important;}
  .navdropdowndiv .dropdown-item{padding-left: 7% !important;}
  .marquee-item img{width:180px !important;height:52px;padding-right:15px !important;}
  .navdropdowndiv {
    width: 80vw !important;height: 70vh;
    overflow: scroll;
  }
  .features{margin-top: 20px !important;}
  .font35 {
    font-size: 1.8em !important;
  }
  #navbarSupportedContent{padding: 1em 0em 2em 1.5em;
    color: #222 !important;
    background-color: #fff;
    border-radius: 20px;}
  #navbarSupportedContent li{padding:0 0.5em 0.5em 0;}
  .hmpg-header{font-size:1.4em !important;}
    .marquee-content {animation: scrolling 18s linear infinite !important;}
    
        .marquee {
            overflow: hidden;
        }
  .wusrmodal{top: 80px;
    left: 0;
    margin: auto !important;
    right: 0;width:321px !important;}
  .navbar-nav svg{margin-top: -2%;}
  .font90x{font-size: 0.85em !important;}
  .font8{font-size: 0.7em !important;}
  .font9{font-size: 0.87em !important;}
  .f7{font-size: 0.7em !important;}
  .navstaticon{width: 24px;height: 24px;}
  .logo{width:120px !important;}
  .logo1{max-width:148px !important;}
  .w30{width:30px !important;height:30px !important;margin-top: 10%;}
  .lhrvwrating{line-height: 30px;}
  .dsktpbtn-pdf-con{z-index: 10001;position: fixed;bottom: 0;top:auto;background-color: #fff;height: 60px;width: 103%;text-align: center;padding-top: 2%;border-top: 2px solid #e0e0e0;transition: bottom 0.3s;color: #717171 !important;font-size: 0.7em !important;}
  .p-topnavcon {
    margin-left: 14%;
  }
  .mt-6 {
    margin-top: 4rem !important;
  }
  .pt-6indx0 {
    padding: 0rem 1.7rem 0rem 1.7rem !important;
  }
  .pt-6indx01 {
    padding: 0rem 0.5rem 0rem 0.5rem !important;
  }
  .mb-30 {
    margin-bottom: 30px;
  }
  .mbladdbtn{position: fixed;bottom: 100px;z-index: 100;right: 7%;}
  .mbladdbtn button{background-color: #0070ff;width: 44px;height: 44px;border-radius: 50%;}
  .like-box{position:fixed;bottom: 80px;right: 10px;}
  .mtopcon{margin-top: 50px !important;}
  .normal-mt-sctn{margin-top: 76px !important;}
  .mblsvgbtn{width: 18px !important;height: 18px !important;}
  .dsktp-ord .ord-sz{font-size: 0.8em !important;}
  .cartordcon .ord-sz{font-size: 0.8em !important;height: 30px !important;line-height: 30px !important;}
  .cartordcon .ord-img{width: 45px !important;height: 45px !important;margin-top:15%;}
  .cartordcon svg{margin-top:15px !important}
  .orderdtls-info{padding-left: 5% !important;}
  .card-header button{font-size: 0.85em;line-height: 0px;}
  .card-header button svg{width: 15px;height: 15px;}
  #accordionFlushExample{padding: 0 20px 0 20px !important;}
  .pabs{left:7%;max-width: 350px;}
  .hddsktp{display: inline-block;}.hddsktp0{display: block !important;}
  .font-9{font-size: 0.9em !important;}
  .topdate p{font-size: 0.7em;}
  .topdate svg{width:20px !important;}
  h6{font-size: 1.1em !important;}
  .f85 svg{width: 20px !important;}
  .hidenav{display:block !important;}
  .prodcon img{height: 45vw;}
  .prod-col{width: 50% !important;}
  .str-con{width: 25px;height: 25px;line-height: 25px;}
  .str-con2{height: 25px;line-height: 25px;}
  .fsz-1{font-size: 0.9em !important;}
  .fsz-95{font-size: 0.85em !important;}
  .section-ttl{font-size: 1em;}
  .pro-img{height: 400px;}
  .mbl-srch{width: 50% !important;display: flex !important;}
  .btm-nav{display: flex !important;position: fixed;bottom:0;height: 100px;background-color: #fff;height: 70px;width: 103%;text-align: center;padding-top: 2%;border-top: 2px solid #e0e0e0;transition: bottom 0.3s;color: #717171;}
  .btm-cart-count{position: absolute;top: 0;}
  .sidebar-cat{width: 320px;}
  .prodt{font-size: 0.8em !important;}
  .sharelinks{right: 2%;}
  .body-font{font-size: 0.85em;}
  h5{font-size: 1em !important;}
  .shp-logo-dtl svg{width: 35px;height: 35px;}
  .vwallbtn{font-size: 0.7em !important;height:30px !important;line-height: 15px !important;}
  .vwallbtn svg{width: 14px;padding-top: 4px;}
  .cart-con{height: 120px;margin-right: calc(-0.5 * var(--bs-gutter-x)) !important;margin-left: calc(-0.5 * var(--bs-gutter-x)) !important;}.cart-con img{height: 120px;}
  .pay-cart{font-size: 0.9em;}
  .pay-cart{height: 45px;line-height: 45px;}
  .banner{height: 150px;max-width: 600px};
  .ord-sz{font-size: 0.8em;height: 22px;overflow: hidden;padding: 1% 0 0 3% !important;}
  .ord-sz1{font-size: 0.8em !important;padding: 1% 0 0 3% !important;}
  .f8{font-size: 0.8em !important;}
  .ord-bg{line-height: 18px;}
  .acc-con{line-height: 50px !important;}
  .dash-max{margin-left: 0 !important;width: 100% !important;}
  .nav-left-sidebar{display: none;}
  .lh-50{font-size: 0.8em !important;}
  .orddetailspopcon{max-height: 70vh;overflow: scroll;}
  .abslt-con{position: absolute;left: 8%;}
  .insd{position: relative;left: -5%;}
  .pageheader-title{font-size: 0.9em;}
  .ordusr-con{width:14px;height: 14px;}
  .filter2{height: auto;font-size: 0.8em;}
  .dashb{padding-top: 0;}
  .usrpopbtn{height: 30px;font-size: 0.8em !important;}
  .lh34{line-height: 34px;}
  .br-50{width: 30px;height: 30px;margin-top: 10%;border-radius: 10px !important;}
  .hdmobile{display: none !important;}
  .hidemobile{display: none !important;}
  .f14{font-size: 1em;}
  .orddetailspopcon .ord-bg{height: auto !important;}
  .orddetailspopcon .ord-sz{font-size: 0.8em !important;}
  .prodedtbtn button{width: 30px !important;height: 30px !important;padding: 0;}
  .prodedtbtn svg{width: 20px !important;height: 20px !important;}
  .pabs .row{
    margin-right: calc(0 * var(--bs-gutter-x)) !important;
    margin-left: calc(0 * var(--bs-gutter-x)) !important;
  }
  .prfl-img{width: 60px !important;height: 60px !important;line-height: 60px;font-size: 2em !important;}
  .strprfldtls{padding-top: 0px;}
  .strloc{font-size: 0.8em;}
  .strloc svg{width: 16px;}
  .rownumsn{margin-top: -4px !important;}
  .dash-top-row{margin-bottom: 5px;}
  .zindx .ord-bg{margin-left: 0% !important;}
  .dtlspg{width: 100% !important;}
  .dashnavshw{margin-left: 0 !important;max-width: 100% !important;padding-bottom: 100px !important;}
  .dashnavshw .pl30{padding-left: 30%;height: 40px;}
  .dashnavshw .pl25{padding-left: 20%;height: 40px;}
  .detail-con{padding-top: 20px;}
  .hdmobile1{display: none;}
  .middle button{font-size: 0.8em;padding: 5px;}
  .middle{font-size: 0.8em;padding: 5px;margin-top: 30px;}
  .plzero{padding-left: 0 !important;}
  .row {
    margin-right: calc(-0.6 * var(--bs-gutter-x)) !important;
    margin-left: calc(-0.6 * var(--bs-gutter-x)) !important;
  }  
  .mlrnew0{margin-left:calc(-0 * var(--bs-gutter-x)) !important;margin-right:calc(-0.3 * var(--bs-gutter-x)) !important;}
  .mlrnew{margin-left:calc(-0.3 * var(--bs-gutter-x)) !important;margin-right:calc(-0.3 * var(--bs-gutter-x)) !important;}
  .cartmaincon .row{
    margin-right: calc(-0.3 * var(--bs-gutter-x)) !important;
    margin-left: calc(-0.3 * var(--bs-gutter-x)) !important;
  }
  .acc-con{
    margin-right: calc(0 * var(--bs-gutter-x)) !important;
    margin-left: calc(0 * var(--bs-gutter-x)) !important;
  }
  .dashhome-rw {
    margin-right: calc(-0.7 * var(--bs-gutter-x)) !important;
    margin-left: calc(-0.7 * var(--bs-gutter-x)) !important;
  }
  .pdfcon{position: relative;}
}
@media only screen and (min-width: 550px) and (max-width: 600px) {
  .a4-container0 {
    width: 500vw;
    transform: scale(0.65);
  }
}
@media only screen and (min-width: 500px) and (max-width: 549px) {
  .a4-container0 {
    width: 500vw;
    transform: scale(0.55);
  }
}
@media only screen and (min-width: 450px) and (max-width: 499px) {
  .a4-container0 {
    width: 500vw;
    transform: scale(0.49);
  }
}
@media only screen and (min-width: 400px) and (max-width: 449px) {
  .a4-container0 {
    width: 500vw;
    transform: scale(0.43);
  }
}
@media only screen and (min-width: 350px) and (max-width: 399px) {
  .a4-container0 {
    width: 500vw;
    transform: scale(0.37);
  }
}
@media only screen and (min-width: 100px) and (max-width: 349px) {
  .a4-container0 {
    width: 500vw;
    transform: scale(0.33);
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
